import * as React from "react"
import { Link } from "gatsby"
import {
  Navbar,
  Nav,
  NavDropdown,
  Container
} from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image'

class Header extends React.Component {


  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  componentDidMount() {
    window.addEventListener('scroll', () => {
      let activeClass = 'normal';
      if (window.scrollY === 0) {
        activeClass = 'top';
      }
      this.setState({ activeClass });
    });
  }

  render() {

    return (
      <Navbar expand="lg" className={`headbg main-header ${this.state.activeClass}`} id="menubar">
        <Container>
          <Link to="/" className="navbar-brand">
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/logo.png"
            alt="coinsclone logo"
            width={230}
            height={56}
            className="logo"
          />
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/white-logo.png"
            alt="coinsclone white logo"
            width={230}
            height={56}
            className="white-logo"
        />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0"
              navbarScroll
            >
               
              <NavDropdown title="Exchange Clone" className="splitt"  renderMenuOnMount={true}>
                <div>
                  <Link className="dropdown-item" to="/binance-clone-script/">Binance Clone Script</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/bybit-clone-script/">Bybit Clone Script</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/okx-clone-script/">OKX Clone Script</Link>
                  <Link className="dropdown-item" to="/coinbase-clone-script/">Coinbase Clone Script</Link>
                  <Link className="dropdown-item" to="/paxful-clone-script/">Paxful Clone Script</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/kraken-clone-script/">Kraken Clone Script</Link>
                </div>
              </NavDropdown>  
              <NavDropdown title="DEX Clone" className="splitt" renderMenuOnMount={true}>
                  <div>
                  <Link className="dropdown-item" to="/pancakeswap-clone-script/">DEX Similar to PancakeSwap</Link>
                  <Link className="dropdown-item" to="/uniswap-clone-script/">DEX Similar to UniSwap</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/decentralized-exchange-like-dydx/">DEX Similar to dydx</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/jupiter-clone-script/">DEX Similar to Jupiter</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/curve-finance-clone-script/">DEX Similar to Curve Finance</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/thorswap-clone-script/">DEX Similar to Thorswap</Link>
                  </div>
              </NavDropdown>
              <NavDropdown title="NFT Marketplace" className="splitt" renderMenuOnMount={true}>
                <div>
                  <Link className="dropdown-item" to="/opensea-clone-script/">OpenSea Like NFT Marketplace</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/blur-clone-script/">Blur Like NFT Marketplace</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/magic-eden-clone-script/">Magic Eden Like NFT Marketplace</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/binance-nft-marketplace-clone-script/">Binance Like NFT Marketplace </Link>
                  <Link className="dropdown-item" to="/superrare-clone-script/">SuperRare Like NFT Marketplace</Link>
                  </div>
              </NavDropdown>
              <NavDropdown title="Non-Custodial Wallet" className="splitt" renderMenuOnMount={true}>
                  <div>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/metamask-wallet-clone-script/">Metamask Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/trustwallet-clone-app-development/">Trust Wallet Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/binance-web3-wallet-clone/">Binance Web3 Wallet Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/coinbase-wallet-clone/">Coinbase Wallet Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/exodus-wallet-clone-script/">Exodus Wallet Clone</Link>
                  </div>
              </NavDropdown>
              <NavDropdown title="Payment Gateway" className="splitt" renderMenuOnMount={true}>
                  <div>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/coinpayments-clone-script/">CoinPayments Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/bitpay-clone-script/">BitPay Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/coingate-clone-script/">CoinGate Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/nowpayments-clone-script/">NOWPayments Clone</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/coinbase-commerce-clone-script/">Coinbase Commerce Clone</Link>
                  </div>
              </NavDropdown>
              <NavDropdown title="Token" className="splitt"  renderMenuOnMount={true}>
                 <div>
                  <Link className="dropdown-item" to="/bep-20-token-development/">BEP20 Token Development</Link>
                  <Link className="dropdown-item" to="/ethereum-token-development/">Ethereum Token Development</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/solana-token-development/">Solana Token Development</Link>
                  <Link className="dropdown-item" to="https://www.coinsclone.com/polygon-token-development/">Polygon Token Development</Link>
                  <Link className="dropdown-item" to="/tron-token-development/">Tron Token Development</Link>
                  </div>
              </NavDropdown>

            </Nav>
            <Nav
              className="startrial ml-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <a href="https://www.coinsclone.com/contact-us/" className="bluebtn headbtn">Contact Us</a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header
