import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { faSkype, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {

  const [whatsappLink, setWhatsappLink] = useState('');
  const [whatsappwebLink, setWhatsappwebLink] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
      const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
      setWhatsappLink(link);
    }
    if (typeof window !== 'undefined') {
      const currentwebUrl = window.location.href;
      const whatsappwebMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentwebUrl}`;
      const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappwebMessage)}`;
      setWhatsappwebLink(link);
    }
  }, []);


    return (
      
      <footer className="footerbottombg">
        <section className="footer-gray-bg fnt-reg">
          <div className="container foot-padd" >
            <div className="row">
              <div className="col-md-4 col-sm-12 col-12 listrightbox footlistbg">
                <p className="h3">Blockchain</p>
                <ul className="foot-list mb-4">
                  <li><Link to="/blockchain-development-company/">Blockchain Development</Link></li>
                  <li><Link to="/cryptocurrency-exchange-development-company/">Cryptocurrency Exchange Development</Link></li>
                  <li><Link to="/cryptocurrency-payment-gateway/">Crypto Payment Gateway Development</Link></li>
                  <li><Link to="/cryptocurrency-wallet-development-company/">Cryptocurrency Wallet Development</Link></li>
                  <li><Link to="/p2p-crypto-exchange-development/">P2P Crypto Exchange Development</Link></li>
                  <li><Link to="/otc-crypto-exchange-development/">OTC Crypto Exchange Development</Link></li>
                  <li><Link to="/white-label-crypto-exchange-software/">White Label Cryptocurrency Exchange Software</Link></li>
                  <li><Link to="/centralized-exchange-development-company/">Centralized Exchange Development Company</Link></li>
                  <li><Link to="/white-label-crypto-wallet/">White Label Crypto Wallet</Link></li>
                </ul>
                <p className="h3">Exchange Clone</p>
                <ul className="foot-list mb-4">
                  <li><Link to="/localbitcoins-clone-script/">LocalBitcoins Clone Script</Link></li>
                  <li><Link to="/remitano-clone-script/">Remitano Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/kucoin-clone-script/">KuCoin Clone Script</Link></li>
                  <li><Link to="/wazirx-clone-script/">WazirX Clone Script</Link></li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-12 col-12 footlistbg company">
               <p className="h3">NFT</p>
                <ul className="foot-list">
                  {/* <li><Link to="/nft-marketplace-development/">NFT Marketplace Development</Link></li> */}
                  <li><Link to="/white-label-nft-marketplace/">White Label NFT Marketplace</Link></li>
                  <li><Link to="/enjin-clone-script/">Enjin Clone Script</Link></li>
                  <li><Link to="/rarible-clone-script/">Rarible Clone Script</Link></li>
                  <li><Link to="/foundation-clone-script/">Foundation Clone Script</Link></li>
                  <li><Link to="/coinbase-nft-marketplace-clone-script/">Coinbase Like NFT Marketplace</Link></li>
                  <li><Link to="/nft-lending-platform-development/">NFT Lending Platform Development</Link></li>
                  {/* <li><Link to="/nft-token-development/">NFT Token Development</Link></li>
                  <li><Link to="/nft-wallet-development-company/">NFT Wallet Development</Link></li> */}
                </ul>
                <p className="h3">DeFi</p>
                <ul className="foot-list">
                  <li><Link to="/defi-development-company/">DeFi Development</Link></li>
                  <li><Link to="/decentralized-exchange-development-company/">Decentralized Exchange Development</Link></li>
                  <li><Link to="/defi-wallet-development-company/">DeFi Wallet Development</Link></li>
                  <li><Link to="/defi-crowdfunding-platform-development/">DeFi Crowdfunding Development</Link></li>
                  <li><Link to="/sushiswap-clone-script/">DEX Similar to SushiSwap</Link></li>
                  <li><Link to="/bakeryswap-clone-script/">DEX Similar to BakerySwap</Link></li>
                  <li><Link to="/1inch-exchange-clone-script/">DEX Similar to 1Inch Exchange</Link></li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-12 col-12 footlistbg exscript">
              <p className="h3 company-hd mt-3">Company</p>
                <ul className="foot-list">
                  <li><Link to="/">Home</Link></li>
                  <li><a href="/blog/">Blog</a></li>
                  <li><Link to="/about-us/">About Us</Link></li>
                  <li><Link to="/contact-us/">Contact Us</Link></li>
                  <li><Link to="/terms-and-conditions/">Terms & Conditions</Link></li>
                  <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                </ul>
                <div className="below">
                  <p className="pharagraph t-black">Email</p>
                  <p className="pharagraph sbold"><a href="mailto:hello@coinsclone.com">hello@coinsclone.com</a></p>
                </div>
                <div className="below">
                  <p className="pharagraph t-black">Whatsapp / Telegram </p>
                  <p className="pharagraph sbold">
                    <a className='mobile-on' href={whatsappLink} rel="noreferrer" aria-label='+91 9500575285' target="_blank">+91 9500575285</a>
                    <a className='mobile-hide' href={whatsappwebLink} rel="noreferrer" aria-label='+91 9500575285' target="_blank">+91 9500575285</a>
                  </p>
                </div>
                <div className="below">
                  <p className="pharagraph t-black">Connect with us</p>
                  <div className="social-bg">
                    <ul className="sociallist">
                      <li><a href="https://t.me/Coinzclone" className='teleg-trk' rel="noreferrer" aria-label='telegram' target="_blank"><img src="https://coinsclone.mo.cloudinary.net/images/Telegram.svg" alt="telegram" /></a></li>
                      {/* <li><a href="https://www.pinterest.com.au/coinsclone/" rel="noreferrer" aria-label='pinterest' target="_blank"><img src="https://coinsclone.mo.cloudinary.net/images/Pinterest.svg" alt="pinterest" /></a></li> */}
                      {/* <li><a href="https://twitter.com/coinsclone" rel="noreferrer" aria-label='twitter' target="_blank"><img className='twitt' src="https://coinsclone.mo.cloudinary.net/images/twitter.svg" alt="twitter" /></a></li> */}
                      <li><a href="https://www.youtube.com/channel/UCqXKLrnGCjIxNEvbvpUmrMw/featured?view_as=subscriber" className='ytube-trk' rel="noreferrer" aria-label='youtube' target="_blank"><img src="https://coinsclone.mo.cloudinary.net/images/Youtube.svg" alt="youtube" /></a></li>
                      {/* <li><a href="https://www.facebook.com/coinsclone4exchange/" rel="noreferrer" aria-label='whatsapp' target="_blank"><img src="https://coinsclone.mo.cloudinary.net/images/FB.svg" alt="facebook" /></a></li> */}
                      {/* <li><a href="https://www.instagram.com/coins_clone/" rel="noreferrer" aria-label='instagram' target="_blank"><img src="https://coinsclone.mo.cloudinary.net/images/Instagram.svg" alt="instagram" /></a></li> */}
                      {/* <li><a href="https://www.linkedin.com/company/coinsclone/" rel="noreferrer" aria-label='linkedin' target="_blank"><img src="https://coinsclone.mo.cloudinary.net/images/Linkedin.svg" alt="linkedin" /></a></li> */}
                      <li><a href="skype:live:hello_20214?chat" className='skype-trk' rel="nofollow" aria-label='skype'><img src="https://coinsclone.mo.cloudinary.net/images/skypeicon.svg" alt="skype" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerbt">
              <p className="text-center mb-0 copyright">Copyright © 2024 All Rights Reserved by Coinsclone. Coinsclone is a subsidiary of Pixel Web Solutions.</p>
          </div>
          <div className="whatsapp-swipe">
            <ul>
              <li className="listwhts mobile-hide">
                <a className="whatsicn"
                  href={whatsappwebLink}
                  rel="noreferrer" aria-label='whatsapp' target="_blank"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </li>
              <li className="listwht mobile-on">
                <a className="whatsicn"
                  href={whatsappLink} target="_blank" rel="noreferrer" aria-label='whatsapp' 
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </li>
              <li className="skypeline">
                <a className="skypicn" rel="nofollow" aria-label='skype' href="skype:live:hello_20214?chat">
                  <FontAwesomeIcon icon={faSkype} />
                </a>
              </li>
              <li className="skypeline telegline">
                <a className="skypicn telegicn" rel="noreferrer" aria-label='telegram' target="_blank" href="https://t.me/Coinzclone">
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </footer>
    );
}

export default Footer